<script setup lang="ts">
// Components
import ExportConsumption from '../components/export/modal/exportConsumption.vue';

// Composables
const { t } = useI18n();

// Data
const exportConsumptionModal = ref(false);
const tabs: { label: string; to: string }[] = [
  {
    label: t('global.alert_configuration'),
    to: '/configuration/alerts',
  },
  {
    label: t('global.production times'),
    to: '/configuration/production-hours',
  },
  {
    label: t('global.contracts'),
    to: '/configuration/contracts',
  },
  {
    label: t('global.areas'),
    to: '/configuration/areas',
  },
];
</script>

<template>
  <ui-page :title="t('global.configurations')" :tabs="tabs">
    <template #header-actions>
      <ui-button v-if="globalStore().getSelectedSite.length === 1" left-icon="Download" @click="exportConsumptionModal = true">
        {{ $t('global.export_consumption') }}
      </ui-button>
    </template>
  </ui-page>

  <!-- Export consumption modal -->
  <export-consumption v-model="exportConsumptionModal" />
</template>
